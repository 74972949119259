import React from "react";

const Landing = React.lazy(() => import("./landing/index"));
const Contact = React.lazy(() => import("./contact/index"));
const Pricing = React.lazy(() => import("./pricing/index"));
const NotFound = React.lazy(() => import("./not-found/index"));

const JavascriptTraining = React.lazy(() => import("./trainings/javascript/index"));
const ReactTraining = React.lazy(() => import("./trainings/react/index"));
const NodeTraining = React.lazy(() => import("./trainings/node/index"));
const GraphqlTraining = React.lazy(() => import("./trainings/graphql/index"));

const trainings = {
    javascript: {
        link: "/javascript-intro",
        path: "/javascript-intro",
        title: "JS Intro",
        component: JavascriptTraining,
        isTopbarDark: true
    },
    react: {
        link: "/react",
        path: "/react",
        title: "ReactJS",
        component: ReactTraining, isTopbarDark: true
    },
    node: {
        link: "/node",
        path: "/node",
        title: "NodeJS",
        component: NodeTraining,
        isTopbarDark: true
    },
    graphql: {
        link: "/graphql",
        path: "/graphql",
        title: "GraphQL",
        component: GraphqlTraining,
        isTopbarDark: true
    },
}

export const routes = {
    ...trainings,
    landing: {
        link: "/",
        path: "/",
        title: "Home",
        component: Landing,
        isTopbarDark: true
    },
    trainings: {
        title: "Trainings",
        link: "/#",
        path: "/#",
        isOpenSubMenu: false,
        child: [
            {title: trainings.javascript.title, link: trainings.javascript.path, path: trainings.javascript.path},
            {title: trainings.react.title, link: trainings.react.path, path: trainings.react.path},
            {title: trainings.node.title, link: trainings.node.path, path: trainings.node.path},
            {title: trainings.graphql.title, link: trainings.graphql.path, path: trainings.graphql.path},
        ],
    },
    pricing: {
        link: "/pricing",
        path: "/pricing", title: "Pricing",
        component: Pricing,
        isTopbarDark: true
    },
    contact: {
        link: "https://nerds.sh/contact",
        path: "https://nerds.sh/contact",
        title: "Contact",
        component: Contact,
        isTopbarDark: true
    },
    mainSite: {
        link: "https://nerds.sh",
        path: "https://nerds.sh",
        title: "Main site",
        component: Contact,
        isTopbarDark: true
    },
    notFound: {
        component: NotFound,
        isWithoutLayout: true,
        exact: false
    }
}